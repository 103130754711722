//
// UserBadge.tsx
//

import { useGetUser } from "@custom-hooks/useApi";
import { getInitials } from "@lib/iso-utils";
import { Avatar } from "@tw-components/ui/avatar";

export function UserBadge() {
  // Get user profile
  const { user, isLoading: loadingUser } = useGetUser();

  // Render component
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-3">
      {user && !loadingUser && (
        <>
          <Avatar initials={getInitials(user.name)} className="tw-size-10" />
          <div className="tw-flex tw-flex-col tw-gap-y-1">
            <div className=" tw-text-12px-semiBold">{user.name}</div>
            <div className=" tw-text-12px-regular">{user.email}</div>
          </div>
        </>
      )}
      {loadingUser && (
        <>
          <div className="tw-dark:tw-bg-interface-gray-dark tw-size-10 tw-animate-pulse tw-rounded-full tw-bg-interface-gray-light" />
          <div className="tw-flex tw-flex-col tw-gap-y-1">
            <div className="tw-dark:tw-bg-interface-gray-dark tw-h-3 tw-w-40 tw-animate-pulse tw-rounded tw-bg-interface-gray-light tw-text-12px-semiBold"></div>
            <div className="tw-dark:tw-bg-interface-gray-dark tw-h-3 tw-w-40 tw-animate-pulse tw-rounded tw-bg-interface-gray-light tw-text-12px-semiBold"></div>
          </div>
        </>
      )}
    </div>
  );
}
