//
// index.tsx - Jobs related functionality
//

import { FetchHookResult } from "@data-types/generic-hook-type";
import { Job, JobProgressStatus, Jobs } from "@data-types/jobs-type";
import { renderAnalyzer, swrFetcher } from "@lib/client-side";
import { useEffect, useRef, useState } from "react";
import useSWR, { useSWRConfig } from "swr";

// Types

/**
 * Extends FetchHookResult to include specific flags for tracking job progress.
 *
 * @template T - The type of data being fetched, such Jobs.
 * @property {boolean} isAnyJobInProgress - Indicates if at least one job is currently in progress.
 */
export type FetchProjectJobsResult<T> = FetchHookResult<T> & {
  isAnyJobInProgress: boolean;
};

// Utils

/**
 * Determines if a job is currently in progress based on its status.
 *
 * @param {JobProgressStatus} status - The current status of the job.
 * @returns {boolean} Returns `true` if the job is in progress, otherwise `false`.
 */
const isJobInProgress = (status: JobProgressStatus): boolean =>
  status !== "completed" && status !== "error";

/**
 * Determines the status of a given job based on its error state and progress.
 *
 * @param {Job} job - The job object to evaluate.
 * @returns {JobProgressStatus} - Returns one of the following statuses:
 *   - "error" if there is an error (job.error is 1).
 *   - "progress" if there is no error (job.error is 0) and progress is less than the total steps.
 *   - "completed" if there is no error (job.error is 0) and progress is equal to the total steps.
 *   - `undefined` if none of the conditions are met.
 *
 * This function helps track the job’s current state, which could be useful for displaying
 * appropriate messages or indicators in the UI.
 */
export function getJobStatus(job: Job): JobProgressStatus {
  let status: JobProgressStatus;
  if (job.error == 1) {
    status = "error";
  } else if (job.error == 0) {
    if (job.progress < job.steps) {
      status = "progress";
    }
    if (job.steps == job.progress) {
      status = "completed";
    }
  }
  return status;
}

// Hooks

/**
 * Custom hook to fetch and monitor project jobs based on a given project ID, with tracking for job progress.
 *
 * This hook retrieves project jobs from the API, analyzes the data to determine if there are jobs in progress,
 * and adjusts the refresh interval based on the job statuses. It also provides several states, such as loading,
 * error, and validation, to help manage data display in the UI.
 *
 * @param {string} projectId - The ID of the project to fetch jobs for.
 * @returns {FetchProjectJobsResult<Jobs>} An object containing:
 *   - `data`: The list of project jobs or `undefined` if no data is available.
 *   - `isLoading`: `true` if the data is currently loading; otherwise, `false`.
 *   - `isError`: Any error encountered during data fetching.
 *   - `isValidating`: `true` if the data is in the process of revalidating; otherwise, `false`.
 *   - `showLoader`: `true` if a loading indicator should be shown.
 *   - `hasData`: `true` if there is job data available.
 *   - `emptyData`: `true` if job data is available but empty.
 *   - `isAnyJobInProgress`: `true` if any job is currently in progress, otherwise `false`.
 */
export function useGetProjectJobs(
  projectId: string
): FetchProjectJobsResult<Jobs> {
  // Manages the interval at which the data is refreshed
  const [refreshInterval, setRefreshInterval] = useState<number>(0);

  // State to track if any job is in progress
  const [isAnyJobInProgress, setIsAnyJobInProgress] = useState<boolean>(false);

  // Holds the previous status of each job to detect status transitions
  const prevJobsStatusRef = useRef<Map<string, JobProgressStatus> | null>(null);

  // SWR function for revalidating projects data
  const { mutate } = useSWRConfig();

  // Fetches project jobs data from the server using SWR with dynamic refresh
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [
        `/api/projects/${projectId}/jobs/nodes`,
        "useGetProjectJobs",
      ],
    swrFetcher,
    {
      refreshInterval, // Refresh interval is controlled by `setRefreshInterval`
      revalidateOnFocus: true, // Revalidate data on window focus
    }
  );

  // Analyze the data state to control loading and empty data indicators
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    true
  );

  // Effect to handle data monitoring and refresh interval adjustment
  useEffect(() => {
    if (!data?.value) return;

    // If data is available, analyze each job’s status
    const projectJobs = data.value as Jobs;
    let testIsInProgress = false; // Track if any job is still in progress
    const jobsStatusMap = new Map<string, JobProgressStatus>(); // Track current status of jobs

    projectJobs.forEach((job) => {
      const status = getJobStatus(job); // Get the current status of the job

      // Revalidate project data if job transitioned from "progress" to "completed"
      if (
        prevJobsStatusRef.current?.get(job.job_id) === "progress" &&
        status === "completed"
      ) {
        setTimeout(() => {
          mutate([`/api/projects`, "useGetUserProjects"]);
          mutate([`/api/projects/${projectId}/nodes`, "useGetProjectNodes"]);
          mutate([
            `/api/projects/${projectId}/databases`,
            "useGetProjectDatabases",
          ]);
          // mutate([`/api/projects/${projectId}`, "useGetProject"]);
          mutate([`/api/projects/${projectId}`, "useGetProjectInfo"]);
          mutate([`/api/projects/${projectId}/apikey`, "useGetProjectApiKey"]);
        }, 1000);
      }

      // Store the current job status
      jobsStatusMap.set(job.job_id, status);

      // Determine if any job is still in progress or has an error status
      if (isJobInProgress(status)) {
        testIsInProgress = true;
      }
    });

    // Update the previous jobs status with the current one
    prevJobsStatusRef.current = jobsStatusMap;

    // Set state for whether any job is in progress
    setIsAnyJobInProgress(testIsInProgress);

    // Adjust the refresh interval: 10 seconds if in progress, otherwise disable
    setRefreshInterval(testIsInProgress ? 10000 : 0);
  }, [data, projectId, mutate]);

  // Return the hook's state and data indicators
  return {
    data: data?.value,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    isAnyJobInProgress,
  };
}
